import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { faChevronRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Modal,
  Backdrop,
  Fade,
  useScrollTrigger,
} from "@mui/material";
import test, { settranscript } from "../../store/actions/test";
import testService from "../../store/services/test.service";
import AnswerInput from "./Questioninput";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Timer from "./timer";
import jwtDecode from "jwt-decode";
import { iparhaiServer } from "../../store/services/config/api.config";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const QuestionCard = ({ questions, copyPaste }) => {
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition({ continuous: true });
  const testId = localStorage.getItem("tid");
  const dispatch = useDispatch();
  const { duration } = useSelector((state) => state.tests);

  const { Mode } = useSelector((state) => state.tests);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [skipCount, setSkipCount] = useState(0);
  const [timeOfTest, setTimeOfTest] = useState(duration);
  const [startTime, setStartTime] = useState(Date.now()); // Add state for question start time
  const [selectedOption, setSelectedOption] = useState("");
  const [assignmentFile, setAssignmentFile] = useState(null)
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null)
  const [skipModal, setSkipModal] = useState(false);
  const [answersDone, setAnswerDone] = useState(0);
  const [textAnswer, setTextAnswer] = useState("");
  const screenSwitches = useSelector((state) => state.proctorReducer.switches);

  const transcripttext = useSelector((state) => state.tests.transcripttext);
  const similarity_score = useSelector(
    (state) => state.proctorcountReducer.similarityscore
  );
  const noface = useSelector((state) => state.proctorcountReducer.noface);
  const truecount = useSelector((state) => state.proctorcountReducer.trueCount);
  const objResponses = useSelector(
    (state) => state.proctorcountReducer.objResponses
  );
  const rightgaze = useSelector(
    (state) => state.proctorcountReducer.rightcount
  );
  const centergaze = useSelector(
    (state) => state.proctorcountReducer.centercount
  );
  const upgaze = useSelector((state) => state.proctorcountReducer.upcount);
  const downgaze = useSelector((state) => state.proctorcountReducer.downcount);
  const leftgaze = useSelector((state) => state.proctorcountReducer.leftcount);
  const [answers, setAnswers] = useState(
    new Array(questions?.length).fill({ answer: "", questionNumber: 0 })
  );
  const [testCompleted, setTestCompleted] = useState(false);
  const [allResponses, setAllResponses] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const now = new Date();
    const target = now.getTime() + duration * 1000;

    const existingPing = localStorage.getItem("ping");
    if (!existingPing) {
      localStorage.setItem("ping", target.toString());
    }
  }, [duration, timeOfTest]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const targetTime = parseInt(localStorage.getItem("ping"), 10);

      if (targetTime && now > targetTime) {
        setTimeOfTest(0);
        clearInterval(interval);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === "m") {
      console.log("press");
    }
    if (
      event.key === "F11" ||
      event.key === "F12" ||
      (event.ctrlKey && event.shiftKey && event.key === "I") ||
      event.key === "Escape"
    ) {
      event.preventDefault();
      return false;
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const handleCopyPaste = (event) => {
    event.preventDefault();
  };

  if (jwtDecode(token).role === 1) {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("contextmenu", handleContextMenu);
    // document.addEventListener("mouseenter", handleMouseMove);
    if (!copyPaste) {
      document.addEventListener("copy", handleCopyPaste);
      document.addEventListener("cut", handleCopyPaste);
      document.addEventListener("paste", handleCopyPaste);
    }
  }

  async function checkDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInput = devices.some(device => device.kind === 'videoinput' && device.deviceId);
      const audioInput = devices.some(device => device.kind === 'audioinput' && device.deviceId);

      const statusText = `Camera: ${videoInput ? 'On' : 'Off'} | Microphone: ${audioInput ? 'Enabled' : 'Disabled'}`;
      // document.getElementById('status').innerText = statusText;

      return { videoInput, audioInput };
    } catch (err) {
      throw new Error(`Error checking devices: ${err.message}`);
    }
  }

  useEffect(() => {
    setAllResponses((prevResponses) => {
      const newLabels = objResponses || [];
      const filteredLabels = newLabels.filter(
        (label) =>
          ![
            "Black Hair",
            "People",
            "Person",
            "Man",
            "Male",
            "Portrait",
            "Adult",
            "Forehead",
            "head",
            "Selfie",
            "selfie",
            "Photography",
            "photography",
            "Man",
            "men",
            "Beard",
            "beard",
            "Cheek",
            "Neck",
            "Eyelash",
            "Face",
            "Finger",
            "Nose",
            "Chin",
            "Eyebrow",
            "Mouth",
            "Hair",
            "Moustache",
            "Head",
            "Hairstyle",
            "Human",
            "Eye",
            "Jaw",
            "Beard",
            "Smile",
            "Ear",
            "Facial hair",
            "Selfie",
            "Gesture",
            "White-collar worker",
            "Sleeve",
            "Sunglasses",
            "Glasses",
            "Eyewear",
            "Vision care",
            "Tartan",
            "Lip",
            "Ceiling",
            "Purple",
            "T-shirt",
            "Facial expression",
            "Muscle",
            "Thumb",
            "Wrist",
            "Room",
            "Collar",
            "Dress shirt",
            "Throat",
            "Ceiling",
            "Dress shirt",
            "Plaster",
            "Ceiling fan",
            "Mirror",
            "Formal wear",
            "Travel",
            "Thumb",
            "Thigh",
            "Chest",
            "Wood",
            "Abdomen",
            "Fur",
            "Long hair",
            "Human body",
            "Building",
            "Sitting",
            "Jewellery",
            "Fashion design",
            "Tints and shades",
            "Household supply",
            "Comfort",
            "Event",
            "Electric blue",
            "Organ",
            "Tourism",
            "Visual arts",
            "Layered hair",
            "Iris",
          ].includes(label)
      );

      return [...prevResponses, ...filteredLabels];
    });
  }, [objResponses]);

  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [skipped, setSkipped] = useState(false);
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();

  const handleButtonClick = async () => {
    try {
      const status = await checkDevices();
      const microphoneStatus = status.audioInput;
      const endTime = Date.now();
      const timeTaken = (endTime - startTime) / 1000; // time taken in seconds
      setStartTime(endTime);
      // setLoading(true);
      setTestCompleted(true);
      localStorage.removeItem("123");
      navigate("/result");
      
      // Submit text answer
      const formData = new FormData();
      formData.append('testId', testId);
      formData.append('sequence', questions[questionNumber].sequence);
      formData.append('userId', userId);
      formData.append('questionId', questions[questionNumber]._id);
      formData.append('marks', questions[questionNumber].marks);
      formData.append('timeTaken', timeTaken);
      formData.append('microphoneStatus', microphoneStatus);
      formData.append('audio', transcript);
      formData.append('pdfPreviewUrl', pdfPreviewUrl);
      formData.append('answer', selectedOption || textAnswer);

      // Append file if available
      if (assignmentFile) {
        formData.append('file', assignmentFile);
      }
      await testService.submittextanswer(formData);

      // Update score if the selected option is correct or deduct negative marks if incorrect

      // Calculate scores
      const tab_switch = screenSwitches;

      // Submit score
      await testService.Submitscore(tab_switch);

    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    SpeechRecognition.startListening({
      continuous: true,
    });
  }, []);

  useEffect(() => {
    if (transcript) {
    }
  }, [transcript]);

  useEffect(() => {
    dispatch(test.updateTestId(testId));
    dispatch(test.updateAnswers(answers));
    dispatch(test.updatePercentage((answersDone * 100) / questions.length));
    setSelectedOption(answers[questionNumber]?.answer || "");
  }, [answers, testId, answersDone, dispatch, questionNumber]);

  const updateAnswerAtIndex = (index, newValue) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = newValue;
    setAnswers(updatedAnswers);
  };

  const handleAnswer = async (e) => {
    // console.log(e.target.value)
    // console.log(questions[questionNumber])
    // const optionsArray = Object.keys(questions[questionNumber]).filter(key => key.startsWith('option') && !key.startsWith('optionImage'));
    // const reqObject = {
    //   question: questions[questionNumber].question,
    //   options: optionsArray.map((option, index) => ({ [index]: option })),
    //   selectedOption
    // }

    // console.log(reqObject)
    // return

    // {
    //   question: question.question_text,
    //   options: question.options.map((option, index) => ({ [index]: option })),
    //   selectedOption: selectedOptionText,
    // }

    // try {
    //   const response = await fetch('http://localhost:8000/generate_explanation', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(),
    //   });

    //   let data = await response.json()
    //   setAnswerExplaination(data)
    // } catch (error) {
    //   console.log(error)

    // }

    if (!e || !e.target) {
      return;
    }
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    updateAnswerAtIndex(questionNumber, {
      answer: selectedValue,
      questionNumber,
    });
  };

  const isReason = true;
  const isDisabled =
    (Mode === "Text-based" && !textAnswer) ||
    (Mode === "MCQ-based" && (!selectedOption || skipped));
  const answer = textAnswer;


  const increment = async () => {
    try {
      const status = await checkDevices();
      const microphoneStatus = status.audioInput;
      const endTime = Date.now();
      const timeTaken = (endTime - startTime) / 1000;
      setStartTime(endTime);
      setTextAnswer("");
      setQuestionNumber(prevQue => prevQue + 1);

      const formData = new FormData();
      formData.append('testId', testId);
      formData.append('sequence', questions[questionNumber].sequence);
      formData.append('userId', userId);
      formData.append('questionId', questions[questionNumber]._id);
      formData.append('marks', questions[questionNumber].marks);
      formData.append('timeTaken', timeTaken);
      formData.append('microphoneStatus', microphoneStatus);
      formData.append('audio', transcript);
      formData.append('pdfPreviewUrl', pdfPreviewUrl);
      formData.append('answer', selectedOption || textAnswer);

      // Append file if available
      if (assignmentFile) {
        formData.append('file', assignmentFile);
      }
      await testService.submittextanswer(formData);
      settranscript("");
      resetTranscript();
    } catch (error) {
      console.error(error);
    }
  };


  const handleSkipQuestion = async () => {
    if (questions.length - 1 > questionNumber && questions[questionNumber]) {
      const endTime = Date.now();
      setStartTime(endTime);
      setTextAnswer("");
      setSkipCount((prev) => prev + 1);
      setQuestionNumber((prevQue) => prevQue + 1);
    } else {
      setSkipModal(true);
    }
  };

  const handleTimerEnd = () => {
    setTestCompleted(true);
  };

  const handlePreviewClick = () => {
    setShowPreview(true);
  };
  const handleClosePreview = () => {
    setShowPreview(false);
  };

  return (
    <>
      {!testCompleted ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "",
            width: "50rem",
            bgcolor: "white",
            p: 4,
            boxSizing: "content-box",
            borderRadius: 2,
          }}
        >
          <Timer
            duration={timeOfTest == 0 ? 0 : duration}
            onTimerEnd={handleTimerEnd}
          />

          <Box sx={{ display: "flex" }}>
            <Typography sx={{ width: "2rem" }} variant="h6" fontWeight={800}>
              {`${questionNumber + 1}.`}
            </Typography>
            <Box
              sx={{
                width: "40rem",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                hyphens: "auto",
                mr: 2,
              }}
            >
              <Typography variant="h6" fontWeight={800}>
                {questions[questionNumber]?.question?.trim()}
              </Typography>

              {questions[questionNumber]?.questionImage && (
                <>
                  <div
                    onClick={handlePreviewClick}
                    className="h-40 overflow-hidden cursor-pointer w-60 mt-3 border"
                  >
                    <img
                      src={questions[questionNumber].questionImage}
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                  </div>
                  <span className="text-sm text-slate-400">
                    Click image to open
                  </span>
                  {showPreview && (
                    <Modal open={showPreview} onClose={handleClosePreview}>
                      <Box sx={style}>
                        <div className="w-[800px] flex justify-center">
                          <img
                            className=""
                            src={questions[questionNumber].questionImage}
                            alt="Preview"
                            style={{ maxHeight: "600px" }}
                          />
                        </div>
                        <Button
                          onClick={() => setShowPreview(false)}
                          variant="contained"
                          style={{ marginTop: "10px" }}
                        >
                          Close
                        </Button>
                      </Box>
                    </Modal>
                  )}
                </>
              )}
            </Box>
            <Box>
              <Paper
                sx={{
                  width: "6rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: "primary.main",
                  color: "white",
                  p: 1,
                }}
              >
                {questions[questionNumber]?.marks} Points
              </Paper>
            </Box>
          </Box>

          <AnswerInput
            Mode={Mode}
            textAnswer={textAnswer}
            setTextAnswer={setTextAnswer}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            questions={questions}
            questionNumber={questionNumber}
            handleAnswer={handleAnswer}
            assignmentFile={assignmentFile} setAssignmentFile={setAssignmentFile}
            setPdfPreviewUrl={setPdfPreviewUrl}
          />

          {/* <Paper elevation={8} className="overflow-y-auto px-2 border-2 border-[#004e8f] h-60">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias animi praesentium numquam nesciunt voluptates qui commodi dicta optio ullam cumque? . Molestias animi praesentium numquam nesciunt voluptates qui commodi dicta optio ullam cumque? Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis quos eaque ipsum enim officiis minus veniam repudiandae, tempora tenetur dolores. Animi culpa beatae aliquid, recusandae praesentium id soluta ex officia commodi? Quas repellendus reiciendis eius modi deserunt esse sunt fugiat veritatis adipisci omnis, sit consectetur fuga quidem odit iure architecto sapiente mollitia sed. Laborum pariatur incidunt similique suscipit error libero quo, temporibus debitis ad, dicta, unde inventore esse at officiis dolores neque aliquid nihil ipsum. Reiciendis alias quaerat consequuntur natus voluptate molestias, est praesentium laboriosam culpa commodi. Minus eos illum quasi nostrum ratione explicabo nesciunt, voluptatibus suscipit? Repellat corporis iusto voluptates magnam, blanditiis odio? Debitis quasi in adipisci cupiditate ipsa, et eaque magnam, enim ducimus sed, dolore assumenda illo reprehenderit impedit quam labore? Laudantium blanditiis accusantium sapiente earum reiciendis sed tempore, et neque error consequuntur non, vero dicta nemo expedita laboriosam harum soluta quidem. Amet quas mollitia magnam alias illo! Odit voluptatibus laboriosam architecto? Sed enim minima repellat provident libero, nam illum veritatis veniam quis vel suscipit facere molestias maiores consequatur adipisci expedita error. Necessitatibus iusto, cumque suscipit voluptates saepe minima explicabo? Atque, quibusdam tenetur molestiae maiores voluptas provident quisquam qui eos modi molestias expedita ex, sint ipsum? Delectus, corrupti.</Paper> */}
          <Box sx={{ display: "", gap: 5, mt: 5 }}>
            {questionNumber !== questions.length - 1 ? (
              <>
                <div className="flex w-full items-center justify-between">
                  <Button onClick={handleSkipQuestion} variant="outlined">
                    Skip
                  </Button>
                  <span>Skip count:{skipCount}</span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={increment}
                    disabled={isDisabled}
                  >
                    <Typography sx={{ mx: 2 }}>Next</Typography>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </div>
              </>
            ) : (
              <div className="flex items-center justify-between">
                <div className="space-x-8">
                  <Button onClick={handleSkipQuestion} variant="outlined">
                    Skip
                  </Button>
                  <Button
                    onClick={() => window.location.reload()}
                    variant="outlined"
                  >
                    Review
                  </Button>
                </div>
                <span>Skip count:{skipCount}</span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (skipCount > 0) {
                      setSkipModal(true);
                    } else {
                      handleButtonClick();
                    }
                  }}
                  disabled={assignmentFile ? false : isDisabled ? true : false}
                >
                  {loading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      style={{ marginLeft: "8px" }}
                    />
                  ) : (
                    "Finish"
                  )}
                </Button>
              </div>
            )}
          </Box>
        </Box>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "red",
              marginBottom: 2,
            }}
          >
            Your time is up! Submit the test
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            disabled={loading}
          >
            {loading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                style={{ marginLeft: "8px" }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={skipModal}
        // onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={skipModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              outline: "none",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h3"
              className="text-center"
            >
              You have <span>{skipCount}</span> skip questions. Do you still
              want to submit test?
            </Typography>
            <div className="flex justify-around mt-10 gap-10">
              <Button onClick={handleButtonClick} variant="outlined">
                Submit
              </Button>
              <Button
                onClick={() => window.location.reload()}
                variant="outlined"
                color="warning"
              >
                Review
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default QuestionCard;

import { Button } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import GenerateQuestionModal from "./GenerateQuestionModal";

const GenerateQuestions = () => {
  const [generatedQuestions, setGeneratedQuestions] = useState([]);

  const [openGenerateQuestionModal, setOpenGenerateQuestionModal] =
    useState(false);
  return (
    <>
      <div className="">
        <Button
          onClick={() => {
            alert("This functionality is under development.")
            // setOpenGenerateQuestionModal(true);
          }}
          startIcon={<AddIcon />}
          variant="contained"
          sx={{
            marginTop: "1rem",
            marginRight: "1rem",
            marginLeft: {
              xs: "17vw", //  between 0px and 600px
              sm: "35vw", //  between 600px and 960px
              md: "50vw", //  between 960px and 1280px
              lg: "50vw", // between 1280px and 1920px
              xl: "50vw", //  above 1920px
            },
            bgcolor: "#004E8F",
            color: "white",
            fontSize: {
              xs: "2.75vw", //  between 0px and 600px
              sm: "2vw", //  between 600px and 960px
              md: "1.25vw", //  between 960px and 1280px
              lg: "1.25vw", // between 1280px and 1920px
              xl: "1.25vw", //  above 1920px
            },
          }}
        >
          Generate Test Questions
        </Button>
      </div>
      <GenerateQuestionModal
        openGenerateQuestionModal={openGenerateQuestionModal}
        setOpenGenerateQuestionModal={setOpenGenerateQuestionModal}
        generatedQuestions={generatedQuestions}
        setGeneratedQuestions={setGeneratedQuestions}
      />
    </>
  );
};

export default GenerateQuestions;
